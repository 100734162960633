import React from 'react';
import { createBrowserRouter } from "react-router-dom";
import LoginPage from './screens/login';
import Dashboard from './screens/dashboard';
import Resources from './screens/resources';
import CompileScreen from './screens/compile';
import MainLayout from './layouts/main';
import PrivateRoute from './components/PrivateRoute';
import ProjectsScreen from './screens/projects';

const router = createBrowserRouter([
    {
      path: "/login",
      element: <LoginPage/>,
    },
    {
        path: "/login/:githubId",
        element: <LoginPage/>,
    },
    {
        path: "/",
        element: <MainLayout/>,
        children: [
            {path: "/dashboard", element: <PrivateRoute><Dashboard/></PrivateRoute>},
            {path: "/resources", element: <Resources/>},
            {path: "/projects", element: <ProjectsScreen/>},
            {path: "/compile/:projectId", element: <CompileScreen/>},
        ]
    },
]);

export default router;