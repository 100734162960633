import React from 'react';
import axios from 'axios';
import { Card, ListGroup, ListGroupItem, Container, Row, Col, Accordion, Button, Modal } from "react-bootstrap";
import { BASE_URL } from '../../utils/config';
import { useSelector } from 'react-redux';
const UserScreenStep1 = ({user, setSelectedRepo}) => {

    const [repos, setRepos] = React.useState([]);
    const [loading, setLoading] = React.useState(false);
    const githubId = useSelector((state)=>state.auth.userData.githubId);

    React.useEffect(()=>{

        const fetchRepos = async (data) => {
            try {
              //const allRepos = await axios.get(`https://api.github.com/users/${data.username}/repos`, {headers: { 'Authorization': `token ${data.accessToken}`}});
              // const allRepos = await axios.get(`https://api.github.com/user/repos?type=private`, {headers: { 'Authorization': `token ${data.accessToken}`}});
              const allRepos = await axios.get(`${BASE_URL}/api/repos/${githubId}`);
              
              setRepos(allRepos.data);
            } catch (error) {
              console.error("Error fetching user repos", error);
            }
          };

        if(user && user != null){
            fetchRepos(user)
        }
    },[user])




    return(
<>
{repos.length > 0 && (
              <Card.Body>
                <Card.Title>Repositories (<b>{repos.length}</b>)</Card.Title>
                <ListGroup>
                  {repos.map((repo, index) => (                    
                    <ListGroupItem key={index}>
                      <Row>
                        <Col lg={10}>{repo.name}{" "}</Col>
                        <Col lg={2}>
                          {(repo.language == 'JavaScript' || repo.language == 'HTML') &&
                          <>
                          <Button variant="secondary" disabled={loading} onClick={()=>setSelectedRepo({githubId: user.githubId, repoUrl: repo.html_url, accessToken: user.accessToken, repoUsername: user.username, repoName: repo.name })}>{loading ? 'Loading...': 'Import'}</Button>
                          </>
                          }
                          </Col>
                      </Row>
            </ListGroupItem>
                  ))}
                </ListGroup>
              </Card.Body>
            )}
</>
    );
}

export default UserScreenStep1;