import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

// Thunk para realizar la autenticación
// export const authenticate = createAsyncThunk('auth/authenticate', async (credentials) => {
//   const response = await axios.post('/api/auth', credentials);
//   return response.data;
// });

const authSlice = createSlice({
  name: 'auth',
  initialState: { userData: null, isAuthenticated: false },
  reducers: {
    setUserData:(state, action)=>{
        state.userData = action.payload;
        state.isAuthenticated = true;
    }
  },
//   extraReducers: (builder) => {
//     builder
//       .addCase(authenticate.fulfilled, (state, action) => {
//         state.status = 'fulfilled';
//         state.token = action.payload.token;
//       })
//       .addCase(authenticate.rejected, (state) => {
//         state.status = 'rejected';
//         state.token = null;
//       });
//   },
});
export const { setUserData } = authSlice.actions;

export default authSlice.reducer;
