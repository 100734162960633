import React, {useState, useEffect} from 'react';
import { Container, Row, Col, Card, Navbar, Nav, Image, ListGroup, ListGroupItem } from 'react-bootstrap';
import { FaServer, FaProjectDiagram, FaClock, FaNetworkWired } from 'react-icons/fa';
import './resources.css';
import io from 'socket.io-client';

const Resources = () => {

    const [socket, setSocket] = useState(null);
    const [data, setData] = useState(null);

    


    React.useEffect(()=>{

        const conectarSocket = () => {
            if (!socket) {
              const newSocket = io("http://173.82.190.73:5000");
              setSocket(newSocket);

              newSocket.on('monitor_data', (data) => {
                console.log(data)
                setData(data);
              });

            }
        };
        
        conectarSocket();
          
      },[])

    

  return (
    <div className="dashboard">
      <Navbar bg="primary" expand="lg" variant="dark">
        <Navbar.Brand href="#home">Dashboard</Navbar.Brand>
        
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ml-auto">
          <Nav.Link href="#deploys">Deploys</Nav.Link>
        <Nav.Link href="#deploys">Domains</Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
      
      <Container>
        {data &&
        <>
        <Row className="mb-5">
          <Col md={3}>
            <Card className="info-card">
              <Card.Body>
                <FaServer className="info-card-icon" />
                <Card.Title>Memory</Card.Title>
                <Card.Text>{parseInt(data.memoryInfo.total / (1024 ** 2))}MB</Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col md={3}>
            <Card className="info-card">
              <Card.Body>
                <FaProjectDiagram className="info-card-icon" />
                <Card.Title>Memory Free</Card.Title>
                <Card.Text>{parseInt(data.memoryInfo.free / (1024 ** 2))}MB</Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col md={3}>
            <Card className="info-card">
              <Card.Body>
                <FaClock className="info-card-icon" />
                <Card.Title>Memory Use</Card.Title>
                <Card.Text>{parseInt(data.memoryInfo.used / (1024 ** 2))}MB</Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col md={3}>
            <Card className="info-card">
              <Card.Body>
                <FaNetworkWired className="info-card-icon" />
                <Card.Title>Bandwitch</Card.Title>
                <Card.Text>250 GB</Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        </>
        }
        
        
        
      </Container>
    </div>
  );
};

export default Resources;
