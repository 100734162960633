import React, { useState, useRef } from 'react';
import io from 'socket.io-client';
import './compiler.css'
import { BASE_URL } from '../../utils/config';
const UserScreenStep2 = ({repoData}) => {

    const [socket, setSocket] = useState(null);
  const [progreso, setProgreso] = useState(null);
  const terminalRef = useRef(null);

  React.useEffect(()=>{
    const conectarSocket = () => {
        if (!socket) {
          const newSocket = io(BASE_URL);
          setSocket(newSocket);

          newSocket.on('iniciando_compilacion', (data) => {
            setProgreso((prevProgreso) => prevProgreso + '\n' + 'Step 1: 🤞 Build your solution 🤞'+ '\n');
          });

          newSocket.on('progreso_compilacion', (data) => {
            setProgreso((prevProgreso) => prevProgreso + '\n' + data);
            terminalRef.current.scrollTop = terminalRef.current.scrollHeight;
          });
        
          newSocket.on('progreso_compilacion_finalizada', (data) => {
            setProgreso((prevProgreso) => prevProgreso + '\n' + data + '\n');
          });

          newSocket.on('iniciando_despliegue', (data) => {
            setProgreso((prevProgreso) => prevProgreso + '\n' + 'Step 2: 🚀 Deploy your solution 🚀'+ '\n');
          });

          newSocket.on('progreso_despliegue_finalizado', (data) => {
            setProgreso((prevProgreso) => prevProgreso + '\n' + 'Step 3: ✅ Success ✅'+ '\n');
          });

          newSocket.on('progreso_despliegue_finalizado_resultado', (data) => {
            setProgreso((prevProgreso) => prevProgreso + '\n' + data);
            terminalRef.current.scrollTop = terminalRef.current.scrollHeight;
          });

          newSocket.on('progreso_despliegue_error', (data) => {
            setProgreso((prevProgreso) => prevProgreso + '\n' + 'Step 3: ❌ Error ❌'+ '\n');
            setProgreso((prevProgreso) => prevProgreso + '\n' + data);
            terminalRef.current.scrollTop = terminalRef.current.scrollHeight;
          });
        }
      };
      if(repoData != null){
        conectarSocket();
      }
      
  },[repoData])

  React.useEffect(()=>{
    const iniciarCompilacion = () => {
        if (socket) {
         setProgreso("🚀 A Cloudsoft Industries Service 🚀"+'\n');
          socket.emit('iniciar_compilacion', repoData);
        } else {
          alert('Conéctate al servidor antes de iniciar la compilación');
        }
      };

    if(repoData && socket){
        iniciarCompilacion()
    }
  },[repoData,socket])

    

    return(
<>
    {/* <button onClick={conectarSocket}>Conectar al servidor</button>
      <button onClick={iniciarCompilacion}>Iniciar compilación</button> */}
      {progreso &&
      <>
      <div className="terminal" ref={terminalRef}>
      <pre>{progreso}</pre>
    </div>
      </>
      }
      
    </>
    );
}

export default UserScreenStep2;