import React from 'react';
import { NavLink } from 'react-router-dom';
import { Navbar, Nav } from 'react-bootstrap';
import './mainLayout.css'
import { Outlet } from 'react-router-dom';
import Logo from '../../assets/img/logo.png';
const MainLayout = ({ children }) => (
  <div>
    <Navbar bg="dark" expand="lg" variant="dark">
        <Navbar.Brand><img src={Logo} style={{width: '80px'}} /></Navbar.Brand>
        
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ml-auto">
          <NavLink to="/dashboard" className="nav-link">Dashboard</NavLink>
          {/* <NavLink to="/dashboard" className="nav-link">Domains</NavLink> */}
          </Nav>
        </Navbar.Collapse>
      </Navbar>

    <main>
      <Outlet/>
    </main>
  </div>
);

export default MainLayout;
