import { Navigate, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';


function PrivateRoute({ children }) {
  const auth = useSelector(state => state.auth.isAuthenticated); // Accede al estado de autenticación en Redux
  let location = useLocation();
  

  return (
    auth
      ? children 
      : <Navigate to="/login" state={{ from: location }} /> 
  );
}

export default PrivateRoute;
