import React from 'react';
import { Container, Row, Col, Card, Navbar, Nav, Image, ListGroup, ListGroupItem, InputGroup , FormControl, Button} from 'react-bootstrap';
import UserScreenStep1 from '../user/step1';
import ConfigProject from '../../components/configProject';
import { useSelector } from 'react-redux';


const ProjectsScreen = () =>{
    const userData = useSelector((state) => state.auth.userData);
    const [selectedRepo, setSelectedRepo] = React.useState(null);
    return(
        <Container>
        {userData &&
            <>
            <Row className="m-5">
                <Col lg={6}>
                    <Card>
                        <Card.Body>
                            <Card.Title>Lets build new project from GitHub</Card.Title>
                            <UserScreenStep1 user={userData} setSelectedRepo={setSelectedRepo}/>
                        </Card.Body>
                    </Card>
                </Col>
                {selectedRepo &&
                <>
                <Col lg={6}>
                    <Card>
                        <Card.Body>
                            <Card.Title>Config Your Deploy To <b>{selectedRepo.repoName}</b></Card.Title>
                            <ConfigProject selectedRepo={selectedRepo}/>
                        </Card.Body>
                    </Card>
                </Col>
                </>
                }
                
            </Row>
            </>
        }
        </Container>
    );
}

export default ProjectsScreen;