import React, {useState, useEffect} from 'react';
import { Container, Row, Col, Card, Navbar, Nav, Image, ListGroup, ListGroupItem, InputGroup , FormControl, Button} from 'react-bootstrap';
import { FaServer, FaProjectDiagram, FaClock, FaNetworkWired } from 'react-icons/fa';
import './dashboard.css';
import axios from 'axios';
import { useParams, useNavigate } from "react-router-dom";
import UserScreenStep1 from '../user/step1';
import UserScreenStep2 from '../user/step2';
import FileExplorer from '../../components/file-explorer';
import { BASE_URL } from '../../utils/config';
import ConfigProject from '../../components/configProject';
import io from 'socket.io-client';
import { useSelector } from 'react-redux';

const Dashboard = () => {
    
    const navigate = useNavigate();
    const userData = useSelector((state) => state.auth.userData);
    const [pasoActual, setPasoActual] = useState(0);
    const [projects, setProjects] = useState(null);
    const [repoData, setRepoData] = useState(null);
    const [selectedRepo, setSelectedRepo] = React.useState(null);

    const githubId = useSelector((state)=>state.auth.userData.githubId);

    React.useEffect(()=>{
      getProjects();
    },[])

    const getProjects = async () => {
      try {
        const result = await axios.get(`${BASE_URL}/api/projects/${githubId}`);
        setProjects(result.data);
      } catch (error) {
        console.log("error al obtener los proyectos", error);
      }
    }

  return (
    <div className="dashboard">
      
      
      <Container>
        <Row className="mb-5">
          <Col md={4}>
            <Card className="info-card">
              <Card.Body>
                <FaProjectDiagram className="info-card-icon" />
                <Card.Title>Projects</Card.Title>
                <Card.Text>{projects && projects.length}</Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col md={4}>
            <Card className="info-card">
              <Card.Body>
                <FaClock className="info-card-icon" />
                <Card.Title>Build Time</Card.Title>
                <Card.Text>0</Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col md={4}>
            <Card className="info-card">
              <Card.Body>
                <FaNetworkWired className="info-card-icon" />
                <Card.Title>Domains</Card.Title>
                <Card.Text>0</Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col>
          <div className="input-group mb-3">
            <input type="text" className="form-control" placeholder="Search Projects" />
            <div className="input-group-append">
              <button className="btn btn-outline-secondary" onClick={()=>navigate("/projects")} type="button">New Project</button>
            </div>
          </div>
          </Col>
        </Row>
        <Row>
          {projects && projects.length > 0 && projects.map((data, index)=>{
            return(
              <Col lg={4} key={index}>
                <Card>
                  <Card.Body>
                    <Card.Title>{data.repoName}</Card.Title>
                    <span className="text-muted">{data.repoUrl}</span><br/>
                    <div className="d-flex justify-content-center">
                      {data.deployUrl && <><a className="btn btn-dark mt-2 mx-2" href={data.deployUrl}>Visit</a> <br/></>}
                    <a className="btn btn-dark mt-2 mx-2" href={"/compile/"+data._id}>Redeploy</a> <br/>
                    <button type="button" className="btn btn-dark mt-2 mx-2" disabled={true}>Custom Domain</button> <br/>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
              
            )
          })
          }
        </Row>
        
      </Container>
    </div>
  );
};

export default Dashboard;
