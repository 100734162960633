import React from 'react';
import { FaGithub } from 'react-icons/fa';
import { Button } from 'react-bootstrap';
import './login.css'
import Logo from '../../assets/img/logo.png'
import { BASE_URL } from '../../utils/config';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { setUserData } from '../../redux/slices/authSlice';



const LoginPage = () => {
  const navigate = useNavigate();  
  const params = useParams();
  const dispatch = useDispatch();
  const {githubId} = params;

  React.useEffect(()=>{

    if(githubId){
       //Get user data and save in store redux
       getUserData();
    }
  },[params])


  const getUserData = async () => {
    const result = await axios.get(`${BASE_URL}/api/user/${githubId}`)
    dispatch(setUserData(result.data))
    navigate("/dashboard");
  }

  return (
    <div className="login-page d-flex justify-content-center align-items-center">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-md-6 col-lg-5">
            <div className="card login-card">
              <div className="card-body text-center">
                <img src={Logo} alt="logo" className="img-fluid text-center" style={{width: '200px'}}/>
                <h3 className="card-title login-title">Cloudsoft Industries</h3>
                <a className="github-button" disable={githubId} href={`${BASE_URL}/auth/github`}>
                  <FaGithub className="github-icon" />
                  <span className="github-text">{githubId ? 'Iniciando...' : 'Iniciar con GitHub'}</span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
