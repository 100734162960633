import React, { useState, useRef } from 'react';
import io from 'socket.io-client';
import './compile.css'
import { BASE_URL } from '../../utils/config';
import { useParams, useNavigate } from "react-router-dom";
import { Container, Row, Col, Card, Navbar, Nav, Image, ListGroup, ListGroupItem, InputGroup , FormControl, Button} from 'react-bootstrap';
const CompileScreen = ({repoData}) => {
    const params = useParams();
  const [step, setStep] = useState(null);
  const [socket, setSocket] = useState(null);
  const [progreso, setProgreso] = useState(null);
  const terminalRef = useRef(null);

  React.useEffect(()=>{

    const conectarSocket = () => {
      
      const newSocket = io(BASE_URL);

      newSocket.on('connect', () => {
        console.log('Conectado al servidor');
        setSocket(newSocket);


        newSocket.on('set_step', (data) => {
          setStep(data);
        });

        newSocket.on('progreso_compilacion', (data) => {
          setProgreso((prevProgreso) => prevProgreso + '\n' + data);
          terminalRef.current.scrollTop = terminalRef.current.scrollHeight;
        });

        newSocket.on('error_general', (data) => {
          setProgreso((prevProgreso) => prevProgreso + '\n' + data);
          terminalRef.current.scrollTop = terminalRef.current.scrollHeight;
        });

      });

      newSocket.on('disconnect', () => {
        console.log('Desconectado del servidor');
        setSocket(null);
      });
        
      };
      if(params != null){
        conectarSocket();
      }
      
  },[params])

  React.useEffect(()=>{
    

    if(socket){
        iniciarCompilacion()
    }
  },[socket])


  const iniciarCompilacion = () => {
    if(socket){
      console.log("iniciando")
      setProgreso("🚀 A Cloudsoft Industries Service 🚀"+'\n');         
      socket.emit('iniciar_compilacion', params.projectId);
    }
    
    };
    

    return(
    <Container>
      <Row>
        
      </Row>
      <Row className="m-4">
      <Col lg={2}>
      <ListGroup>
        <ListGroupItem as="li" active={step == 1 ? true : false }>Get repo info</ListGroupItem>
        <ListGroupItem as="li" active={step == 2 ? true : false }>Build your solution</ListGroupItem>
        <ListGroupItem as="li" active={step == 3 ? true : false }>Start Deploy</ListGroupItem>
        <ListGroupItem as="li" active={step == 4 ? true : false }>Copying files</ListGroupItem>
        <ListGroupItem as="li" active={step == 5 ? true : false }>Create config</ListGroupItem>
        <ListGroupItem as="li" active={step == 6 ? true : false }>Assign Domain</ListGroupItem>
        <ListGroupItem as="li" active={step == 7 ? true : false }>Deploy success</ListGroupItem>
      </ListGroup>

      </Col>
      <Col lg={10}>
        {progreso &&
        <>
        {/* <button type="button" className="btn btn-success" onClick={()=>iniciarCompilacion()}>Compilar</button> */}
        <div className="terminal" ref={terminalRef}>
          <pre>{progreso}</pre>
        </div>
        </>
        }
      </Col>
        
      </Row>
    </Container>
    );
}

export default CompileScreen;