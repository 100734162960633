import React from 'react';
import axios from 'axios';
import { Card, ListGroup, ListGroupItem, Container, Row, Col, Accordion, Button, Modal } from "react-bootstrap";
import { BASE_URL } from '../../utils/config';
import { useNavigate } from 'react-router-dom';

const ConfigProject = ({selectedRepo}) => {

    const navigate = useNavigate();

    React.useEffect(()=>{
        if(selectedRepo){
            setConfigProject(selectedRepo)
        }
    },[selectedRepo])

    const [configProject, setConfigProject] = React.useState(null);
    const [loading, setLoading] = React.useState(false);

    const handleChange = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        setConfigProject({...configProject, [name]:value});
        
    }

    const handleDeploy = async () => {
        try {
            setLoading(true);
            const result = await axios.post(`${BASE_URL}/api/deploy/`, {data: configProject}, {headers: { 'Authorization': `token ${configProject.accessToken}`}});
            console.log(result.data.data);
            setLoading(false);
            navigate("/compile/"+result.data.data._id)
            // setLoading(false);
            // setRepoData(result.data);
            // setShowModal(false);
            //setPasoActual(1);
            
          } catch (error) {
            console.error("Error fetching user repos", error);
          }
    }

    return(
        <>
        <Card>
            <Card.Body>
                <div className="alert alert-info">
                make sure the project you want to deploy is a react project, projects like vue, angular, gatsby, next, html will be available soon.
                </div>

            <div className="mb-3">
                    <label>Select your framework</label>
                    <select className="form-control" name="projectPreset" onChange={(e)=>handleChange(e)}>
                        <option value="ReactJS">React JS</option>
                    </select>
                    
                </div>

                <div className="mb-3">
                    <label>Front Project Folder</label>
                    <input type="text" name="projectFolder" className="form-control" placeholder='Default folder is `.` , other example is `/front`' onChange={(e)=>handleChange(e)} />
                    <small>Put path to locate your front project files in your repository</small>
                    <br/>
                    <small>Leave empty if do not have changes default settings</small>
                </div>

                <div className="mb-3">
                    <label>Output build folder</label>
                    <input type="text" name="projectOutputFolfer" className="form-control" placeholder='Default folder is `/build` ' onChange={(e)=>handleChange(e)}/>                    
                    <small>Put path to locate your builded files</small>
                    <br/>
                    <small>Leave empty if do not have changes default settings</small>
                </div>

                <div className="mb-3">
                    <label>Install command</label>
                    <input type="text" name="projectInstallCommand" className="form-control" placeholder='Default command is `npm install` ' onChange={(e)=>handleChange(e)}/>                    
                    <small>Leave empty if do not have changes default settings</small>
                </div>

                <div className="mb-3">
                    <label>Build command</label>
                    <input type="text" name="projectBuildCommand" className="form-control" placeholder='Default command is `npm run build`' onChange={(e)=>handleChange(e)}/>                    
                    <small>Leave empty if do not have changes default settings</small>
                </div>

                <div className="mb-3">
                    <button type="button" className='btn btn-info' disabled={loading} onClick={()=>handleDeploy()}>{loading ? 'Requesting...' : 'Request Deploy' }</button>
                </div>

                
            </Card.Body>
          </Card>
        </>
    );

}

export default ConfigProject;